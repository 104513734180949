export default defineNuxtRouteMiddleware((to, from) => {
  const user = useUserStore()
  if (!user.isLogin) {
    return navigateTo({
      path: '/app/login',
      query: {
        redirectTo: to.fullPath,
      },
    })
  }
})
